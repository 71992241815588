.full-screen{
     width: 100%; 
     height: 100%;
     display: flex;
     flex-direction: column;
     position: relative;
}

.middle-frame{
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
}

.middle-side-bar{
    display: flex; 
    height: 100%
}