.full-screen {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.top-bar-div {
    width: 100%;
    height: 35px;
    display: flex;
}

.top-bar-div-mobile {
    width: 100%;
    height: 35px;
    display: flex;
}

.centre-div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: auto;
    width: 400px;
    border-width: 1px;
    border-style: solid;
    padding: 15px;
    border-radius: 5px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    border-color: lightgray;
    background-color: whitesmoke;
}

.title-class {
    margin: 0px 0px 10px 0px;
    padding: 3px;
    color: darkgray;
    height: 15px;
    text-align: right;
    font-weight: bold;
    font-size: 16px;
}

.confirm-button {
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 3px;
    height: 100%;
    margin: 5px 0px 1px 0px;
    border-color: lightgray;
    cursor: pointer;
    padding: 5px 3px;
}

.error-text {
    display: flex;
    color: red;
    font-weight: bold;
    font-size: 12px;
    justify-content: right;
}

.success-text {
    display: flex;
    color: green;
    font-weight: bold;
    font-size: 12px;
    justify-content: right;
}