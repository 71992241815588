.App {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.App {
    background: #fff;
    overflow: hidden;
    overflow-anchor: none;
}

.fullscreen-enabled .App {
    background: #fff;
}

.gm-style-cc {
    display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
    display: none !important;
}

th,
td {
    padding-left: 8px;
    padding-right: 8px;
}