/* Pagination links */
.pagination a {
    color: black;
    float: left;
    padding: 2px 6px;
    text-decoration: none;
    transition: background-color .3s;
}

/* Style the active/current link */
.pagination a.active {
    background-color: dodgerblue;
    color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.chsft_no_pointer {
    pointer-events: none;
}

.chsft_normal_pointer {
    pointer-events: auto
}