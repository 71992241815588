.confirm-button {
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 3px;
    height: 100%;
    margin: 5px 0px 1px 0px;
    border-color: lightgray;
    cursor: pointer;
    padding: 5px 3px;
}

.general-account-header {
    margin-top: 10px;
    text-align: left;
    font-size: 10px;
    font-style: italic;
    text-decoration-line: underline
}

.account_setup_active_tab_header {
    border-bottom: none;
    border-color: blueviolet;
    font-weight: bold;
}

.account_setup_tab_header {
    border-color: gray;
}