.full-screen {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.error-style {
    display: flex;
    color: red;
    font-weight: bold;
    font-size: 10px
}

.confirm-button {
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 3px;
    height: 100%;
    margin: 5px 0px 1px 0px;
    border-color: lightgray;
    cursor: pointer;
    padding: 5px 3px;
}

.link-style {
    width: 100%;
    height: auto;
    margin: 0px 0px 10px 0px;
}

.link-div {
    display: flex;
    text-align: center;
    height: auto;
    width: 200px;
    align-self: end;
    margin-left: auto;
    margin-right: auto;
}

.login-box-div {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: auto;
    width: 400px;
    border-width: 1px;
    border-style: solid;
    padding: 15px;
    border-radius: 5px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    border-color: lightgray;
}