.chsft_rem_cell_pad {
    padding: 0px;
    width: "100%";
}

.chsft_grid_orange,
.chsft_grid_text_orange,
.chsft_grid_green,
.chsft_grid_text_green,
.chsft_grid_red,
.chsft_grid_text_red,
.chsft_grid_cell {
    display: "flex";
    justify-content: center;
    display: flex;
    width: 100%;
}

.chsft_grid_orange,
.chsft_grid_text_orange {
    background: rgba(255, 68, 0, 0.05);
}

.chsft_grid_text_orange {
    font-weight: 400;
    color: orangered;

}

.chsft_grid_green,
.chsft_grid_text_green {

    background: rgba(50, 205, 50, 0.05);
}

.chsft_grid_text_green {
    font-weight: 500;
    color: limegreen;
}

.chsft_grid_red,
.chsft_grid_text_red {
    background: rgba(255, 0, 0, 0.05);
}

.chsft_grid_text_red {
    font-weight: 500;
    color: red;
}