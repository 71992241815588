/* hide close button in info-window */
/* button.gm-ui-hover-effect {
    visibility: hidden;
} */

/* clear the paddings  */
.gm-style .gm-style-iw-c {
    padding: 10px;
}

/* remove the white space */
/* .gm-style-iw .gm-style-iw-d {
    overflow: auto !important;
} */