.main-frame {
    align-items: center;
    justify-content: center;
    display: flex;
    justify-self: center;
}

.text-display {
    color: whitesmoke;
    margin: 0px 5px;
}